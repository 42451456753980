.weiter_kaution button {
  border-radius: 26px 26px 26px 26px;
  background-color: #62ab12;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 10px 15px;
  text-decoration: none;
  border: 1px solid #62ab12;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Myriad Pro Regular", sans-serif;
}

.Switch-Kaution {
  margin-left: 20px;
  display: inline-flex;
  width: 58px;
  height: 38px;
  overflow: hidden;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
  z-index: 0;
  vertical-align: middle;
}
.Switch-Kaution .MuiButtonBase-root{
  display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    padding: 9px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    color: rgb(255, 255, 255);
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.stepskaution {
  width: calc(70% - 40px);
  padding: 25px 20px;
  margin:0 auto
}

.stepskaution img {
  width: 1.3rem;
  height: 1.3rem;
  filter: brightness(0.5);
}

.stepskaution div .MuiStepConnector-line {
  background-color: #1B8EE9;
  margin: 0px 2px;
}

.stepskaution .MuiStepConnector-root.Mui-active .MuiStepConnector-line {
  background-image: linear-gradient(95deg, #1B8EE9 0%, #1B8EE9 50%, #1087e5 100%);
}

.stepskaution div .css-gujj89 {
  background-color: #ccc;
  z-index: 1;
  color: #fff;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: linear-gradient(136deg, #F4BF55 0%, #F4BF55 50%, #F4BF55 100%);
}

.stepskaution span .MuiStepLabel-label {
  background-color: #F4BF55;
  border-radius: 5px;
  margin: 0;
  color: #00457C;
  font-size: 12px;
  font-family: 'Myriad Pro Regular', sans-serif;
  width: auto;
  min-width: 6rem;
  padding: 2px;
  text-align: center;
  margin-left: -1.5rem;
  margin-top: 0.65rem;
}

.stepskaution span .MuiStepLabel-label:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 8px solid #F4BF55;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

.stepskaution span .MuiStepLabel-label.Mui-completed {
  color: #00457C;
  font-weight: 400;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: .7rem;
  padding: 3px;
}

.Weiter {

  text-align: end
}

.Weiter .WeiterButton {
  border-radius: 26px 0px 0px 26px;
  background-color: #62AB12;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border: 1px solid #62AB12;
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px 10px;
  font-weight: 600;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 1rem;
}

/* .stepskaution span .MuiStepLabel-iconContainer div {
  width: 35px;
  height: 35px;
} */

.iconskautionsteps {
  height: 20px;
  width: 20px;
}