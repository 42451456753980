.StromIcon {
    margin-top: 15px;
    width: 40px;
    margin-left: -725px;
    filter: brightness(0) invert(1);

}

.GazIcon {
    margin-top: 15px;
    width: 40px;
    height: 40px;
    margin-left: -725px;
    filter: brightness(0) invert(1);

}

.WeitereIcon {
    margin-top: 15px;
    width: 40px;
    margin-left: -725px;
    margin-bottom: 9px;
    filter: brightness(0) invert(1);
}

.WasserIcon {
    margin-top: 15px;
    width: 40px;
    height: 40px;
    margin-left: -725px;
    filter: brightness(0) invert(1);

}

.StromzahleranmeldenButton button {
    display: flex;
    text-decoration: none;
    border-radius: 30px;
    border: 2px solid rgba(255, 255, 255, 1);
    opacity: 1;
    background-color: rgba(98, 171, 18, 1);
    color: rgba(255, 255, 255, 1);
    font-family: 'Myriad Pro Regular', sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 55px;
    padding: 8px;
}

.border-left-none {
    border-left: none;
}

.CameraPhotos {
    color: #FFFFFF;
    background-color: #009FE3;
    width: 170px;
    height: 170px;
    border-radius: 30px;
}



.formCounter {
    display: flex;
    background-color: #FFFFFF;
    width: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-between;
    align-items: baseline;
    margin-left: 5%;
}

.formCounterGaz {
    display: flex;
    background-color: #FFFFFF;
    width: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: center;
    align-items: baseline;
    margin-left: 5%;
}

.formCounterWasser {
    display: flex;
    background-color: #FFFFFF;
    width: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: center;
    align-items: baseline;
    margin-left: 5%;
}

.LigneStromCounter {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: baseline;
    margin-right: 0%;
}

.aftercardsheilo {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 0.75%;
    padding-bottom: 0.75%;
    background-color: #FFFFFF;
    margin: 0px auto;
    align-content: center;
    flex-direction: row;
    border-radius: 15px;
}

.heizolcard {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 2%;
    margin: 0px auto;
    align-content: center;
    flex-direction: row;
    border-radius: 0px 0px 15px 15px;
    background-color: #F3F3F3;
}

.aftercardselectro {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-top: 0.75%;
    padding-bottom: 0.75%;
    background-color: #FFFFFF;
    margin: 0px auto;
    align-content: space-around;
    flex-direction: column;
    border-radius: 15px;
}

.formCameraElectro {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.formElectro {
    display: flex;
    background-color: #FFFFFF;
    width: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin-left: 5%;
}

.image-grid-electro {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
}
.image--container svg{
    top: inherit !important;
    bottom: 0px ;
}