.HeaderIcons {
    margin-top: 15px;
    width: 40px;
    height: 40px;
    /* margin-left: -725px; */
    filter: brightness(0) invert(1);
}
.tickIcon {
  margin-top: 15px;
  width: 40px;
  height: 40px;
  margin-left: 5%;
  filter: brightness(0) invert(1);
}
.aftercardsCounterOverview {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 0.75%;
  padding-bottom: 0.75%;
  background-color: #FFFFFF;
  margin: 0px auto;
  align-content: center;
  flex-direction: column;
  border-radius: 10px 0px 15px 15px;
}
#buttonUnterschrift{
  display: inline;
}
.passation_button button{
  width: 100% !important;
}
.passation_button{
  padding: 10px 55px ;
}
.OvwerviewGlobalButton button{
    display: flex;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    height: 63px;
    border-radius: 30px;
    border: 2px solid rgba(255,255,255, 1);
    opacity: 1;
    background-color: rgba(98,171,18, 1);
    margin-top: 10px;
    margin-bottom: 20px;
    color: rgba(255,255,255,1);
    font-family: 'Myriad Pro', sans-serif;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.einzug.width{
  width: auto !important;
}
.OvwerviewGlobalButton button:disabled{
  margin: 0 auto;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  height: 63px;
  border-radius: 30px;
  border: 2px solid rgba(255,255,255, 1);
  opacity: 1;
  background-color: #9c9e9a !important;
  margin-top: 10px;
  margin-bottom: 20px;
  color: rgba(255,255,255,1);
  font-family: 'Myriad Pro', sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}


.AbschlussbemerkButtonIcon {
    width: 25px;
    height: 25px;
    margin-left: 15px;
    filter: brightness(0) invert(1);
}


.Wohnungsgeberbescheinigung {
    display: flex;
    color: var(--white);
    font-family: 'Myriad Pro', sans-serif;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    margin-top: -8px;
    margin-bottom: 8px;
  }

  
.Wohnungsgeberbescheinigung h3 {
    opacity: 1;
    font-family: 'Myriad Pro', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 30px;
    margin-bottom: 9px;
    width: 350px;
    text-align: left;
  }
  
  .WohnungsgeberbescheinigungIcon {
    width: 40px;
    height: 40px;
    margin-left: -606px;
    filter: brightness(0) invert(1);
  }

.first_Wohnungsgeberbescheinigung {
    margin-left: 330px;
    display: flex;
    color: var(--white);
    font-family: 'Myriad Pro', sans-serif;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-start;
  }  

.sub_Wohnungsgeberbescheinigung {
    border-radius: 15px 15px 0px 0px;
    width: 565px;
    height: 80px;
    display: flex;
    color: var(--white);
    font-family: 'Myriad Pro', sans-serif;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    background-color: #F4BF55;
    margin-right: -315px;
    margin-top: 2px;
    margin-bottom: -8px;
  }

  .sub_Wohnungsgeberbescheinigung h3 {
    opacity: 1;
    font-family: 'Myriad Pro', sans-serif;
    text-transform: none;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-left: 30px;
    margin-bottom: 25px;
  }

.formAbschlussbemerkung {
    display: flex;
    background-color: #FFFFFF;
    width: 92%;
    padding-top: 1%;
    padding-bottom: 1%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: baseline;
    margin-left: 4.5% !important;
}
.finalLine{
  display: inline-flex;
  margin-top: 15px;
  align-items: unset;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  width: 100%;
 
}
#finalLine{
  width: 100%;
}


/*****************************/
/* .HeaderIcons {
    margin-top: 15px;
    width: 40px;
    height: 40px;
    margin-left: -725px;
    filter: brightness(0) invert(1);
} */

.downloadLine{
  display: inline-flex;
  margin-top: 2%;
  align-items: unset;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  width: 100%;
}


.AbschlussbemerkButtonIcon {
    width: 25px;
    height: 25px;
    margin-left: 15px;
    filter: brightness(0) invert(1);
}


.Wohnungsgeberbescheinigung {
    display: flex;
    color: var(--white);
    font-family: 'Myriad Pro', sans-serif;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    margin-top: -8px;
    margin-bottom: 8px;
  }

  
.Wohnungsgeberbescheinigung h3 {
    opacity: 1;
    font-family: 'Myriad Pro', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 30px;
    margin-bottom: 9px;
    width: 350px;
    text-align: left;
  }
  
  .WohnungsgeberbescheinigungIcon {
    width: 40px;
    height: 40px;
    margin-left: -606px;
    filter: brightness(0) invert(1);
  }

.first_Wohnungsgeberbescheinigung {
    margin-left: 330px;
    display: flex;
    color: var(--white);
    font-family: 'Myriad Pro', sans-serif;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-start;
  }  

.sub_Wohnungsgeberbescheinigung {
    border-radius: 15px 15px 0px 0px;
    width: 565px;
    height: 80px;
    display: flex;
    color: var(--white);
    font-family: 'Myriad Pro', sans-serif;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    background-color: #F4BF55;
    margin-right: -315px;
    margin-top: 2px;
    margin-bottom: -8px;
  }

  .sub_Wohnungsgeberbescheinigung h3 {
    opacity: 1;
    font-family: 'Myriad Pro', sans-serif;
    text-transform: none;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-left: 30px;
    margin-bottom: 25px;
  }

.formAbschlussbemerkung {
    display: flex;
    background-color: #FFFFFF;
    width: 92%;
    padding-top: 1%;
    padding-bottom: 1%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: baseline;
    margin-left: 4.5%;
}
.hrOverview {
  opacity: 1;
  border-bottom: 0px dashed rgba(151,151,151, 1);
  margin-bottom: 0px;
  margin-top: opx;
  width: 100%;
}


.formProductNameOvwerview {
  display: flex;
  background-color: #FFFFFF;
  width: 92%;
  padding-top: 1%;
  padding-bottom: 1%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 4.5%;
}
.formKundenOvwerview{
  display: flex;
  background-color: #FFFFFF;
  width: 92%;
  padding-top: 1%;
  padding-bottom: 1%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 4.5%;

}
.formMondat{
  display: flex;
  background-color: #FFFFFF;
  width: 92%;
  padding-top: 1%;
  padding-bottom: 1%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 4.5%;

}
.formZahler{
  display: flex;
  background-color: #FFFFFF;
  width: 92%;
  padding-top: 1%;
  padding-bottom: 1%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 4.5%;
}
.formSchlussel{
  display: flex;
  background-color: #FFFFFF;
  width: 92%;
  padding-top: 1%;
  padding-bottom: 1%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 4.5%;
}
.subLigne{
 
    display: flex;
    flex: 1;
    margin-bottom: auto;
    flex-direction: column;
     row-gap: 10%;
    max-width: calc(96%/2);
    text-align: left;
  
}
.fieldImg{
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 10px;
  max-width: calc(96%/2);
  text-align: left;

}

.resultField{
  width: 100%;
  margin-top: 5px;
}
#field1Title{
  width: 100%;}

#titleBold{
  font-weight: bold;
  font-size: larger;
  width: 100%;
}
#kundeninformationen :nth-child(1){
color: #009FE3;
margin-bottom: 0px;
font-size: 20px;
margin-top: 10px;

}
#Schlusse{
  justify-content: center;
  color: #009FE3;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}
 .titleField p{
  margin-bottom: 0;
  overflow-wrap: break-word;
  display: block;
  text-align: left;
  width: 100%;
  color: var(--text);
  font-size:1rem ;
  font-weight: inherit;
  
}
.downloadLine a{
  text-decoration:underline;
  font-weight: bold;
  font-size: large;

}
.subLigne .titleField p{
  overflow-wrap: break-word;
    display: block;
    text-align: left;
    width: 100%;
    color: #0e0d0d;
    font-size: larger;
    font-weight: inherit;

}
#buttonUnterschrift{
  color: #FFFFFF;
}
/* .sigCanvas{
  border: 1px solid black;
  width: calc(96%/2);
  min-height: 40%;
  background-color: #009FE3;
} */
.signature {
  display: block;

  border: 2px solid black;
  width: 500px;
  height: 200px;
}
.unterchriftImg{
  padding-right: 5%;
  filter: brightness(0) invert(1)
}
 .resultField span  {
  overflow:visible;
  text-align: justify;
  width: 100%;
  color: #000;
  font-weight: inherit;



}
#signature{
  padding-top: 1%;
  row-gap: 5%;
}
.SonCardUnterschriften { 
  background-color: #047432 !important;
 }
.imgTitle{
  filter: brightness(0.5);
  width: 20px;
  height: 20px;
 
}
.titleField{
  max-width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
}
.field{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 50%;
  text-align: left;
}
.field1{
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 10px;
  max-width: calc(96%/3);
  text-align: left;
}
.field2{
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 10px;
  max-width: calc(98%/2);
  text-align: left;
}
.field3{
  
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: 10px;
    max-width: 100%;
    text-align: left;

}
.Ligne{
  column-gap: 2%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  /* align-items: baseline; */
  /* justify-content: space-between; */
  width: 100%;
}
.modify-pen-icon {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
}

.modify-pen-icon svg {
  border-radius: 50% 50%;
  border: 2px solid;
  width: 55px;
  height: 55px;
  padding: 6px;
}
.defaultImageIcon {
  background-color: #dcd4d4e5; 
  border-radius: 3%; 
  color: #FFFFFF;
}
.weiter-protocolltypeUnterschrift{
  padding-top: 2%;
width: 70%;
	display: flex;
	align-items: center;
	
	border: none;
}
.weiter-protocolltypeUnterschrift button {
    display: flex;
	border-radius:  26px 26px 26px 26px;
    background-color: #62AB12;
    color: #fff;
    padding: 10px;
    text-decoration: none;
    margin: 4px 2px;
    border: 1px solid #62AB12;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    height: 54px;
    font-weight: 600;
    padding: 2%;
    font-family: var(--thm-font);
    font-size: 16px;
}


