  * {
    font-family: var(--heading-font);
  }

  .liegenschaftListIcons {
    width: 2.25rem;
    height: 1.75rem;
    filter: invert(47%) sepia(63%) saturate(5633%) hue-rotate(170deg) brightness(99%) contrast(101%);
  }
.import_document button{
  width: 100% !important;
  margin-left: 0px !important;
  padding: 0px 50px;
}
.formUploadDoc p {
  width: 100%;
  margin-bottom: 0px;
  text-align: center;
}
.formUploadDoc{
  margin-bottom: 0px !important;
  padding: 0px 15px;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  height: 50px;
  border-radius: 30px;
  border: 2px solid rgba(98,171,18, 1);
  opacity: 1;
  background-color: rgba(98,171,18, 1);
  color: rgba(255,255,255,1);
  font-family: 'Myriad Pro', sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: none;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.input-fields {
  display: none;
}
.uploaded-files-list {
  list-style-type: none; /* Remove default list bullets */
  padding: 0;
  margin: 0;
}
.uploaded-files-list span{
  color:#333;
  text-transform:initial !important;
}
.cursor_pointer{
  cursor: pointer;
}
.uploaded-files-list li {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f5f5f5; /* Background color for each list item */
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploaded-files-list li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

/* Style the hover effect for list items */
.uploaded-files-list li:hover {
  background-color: #ddd;
}
  .apartementButton button{
    display: flex;
    text-decoration: none;
    cursor: pointer;
    width: 85%;
    height: 50px;
    border-radius: 30px;
    border: 2px solid rgba(98,171,18, 1);
    opacity: 1;
    background-color: rgba(98,171,18, 1);
    margin-left: 7.5%;
    margin-top: 10px;
    margin-bottom: 20px;
    color: rgba(255,255,255,1);
    font-family: 'Myriad Pro', sans-serif;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.groupCard {
  color: #000000;
  font-family: var(--heading-font);
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  align-items: left;
}

.cardLiegenschaft {
  box-shadow: none;
  align-items: flex-start;
}

.bodycardLiegenschaft {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.diviconcardLiegenschaft {
  flex-basis: 10%;
  align-self: flex-start;
}

.divarticlecardLiegenschaft {
  flex-grow: 1;
  margin-left: 3%;
}

.textcardLiegenschaft {
  margin-bottom: -2%;
  text-transform: none;
}

.mycardTitle {
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;
}

.NcounterKey input[type='text'] {
  text-align: center;
  padding-left: 1rem;
}