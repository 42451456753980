body {
    font-family: 'Myriad Pro', sans-serif;
}
.compteur {
    margin-top: 15px;
    width: 60px;
    margin-left: 5%;
    filter: brightness(0) invert(1);


}
.firstBloc{
    display: flex;
}

.GazIcon {
    margin-top: 15px;
    width: 40px;
    height: 40px;
    margin-left: 5%;
    filter: brightness(0) invert(1);

}

.WeitereIcon {
    margin-top: 15px;
    width: 40px;
    margin-left: 5%;
    margin-bottom: 9px;
    filter: brightness(0) invert(1);
}

.WasserIcon {
    margin-top: 15px;
    width: 40px;
    height: 40px;
    margin-left: 5%;
    filter: brightness(0) invert(1);

}

.ZahlerGlobalButton button{
    display: flex;
    text-decoration: none;
    cursor: pointer;
    width: 85%;
    height: 50px;
    border-radius: 30px;
    border: 2px solid rgba(255,255,255, 1);
    opacity: 1;
    background-color: rgba(98,171,18, 1);
    margin-left: 7.5%;
    padding-top: 10px;
    padding-bottom: 10px;
    color: rgba(255,255,255,1);
    font-family: 'Myriad Pro', sans-serif;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}



.formCounter{
    display: flex;
    background-color: #FFFFFF;
    width: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-between;
    align-items: baseline;
    margin-left: 5%;
}

.formCounterGaz {
    display: flex;
    background-color: #FFFFFF;
    width: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: center;
    align-items: baseline;
    margin-left: 5%;
}

.formCounterWasser {
    display: flex;
    background-color: #FFFFFF;
    width: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: center;
    align-items: baseline;
    margin-left: 5%;
}
.LigneStromCounter {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: baseline;
    margin-right: 0%;
}
.LigneStromCounter1 {
    margin-left: -2%;
    display: flex;
    column-gap: 1rem;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 0%;
}
.cameraCounter{
    padding-top: px;
    padding-bottom: 20px;
    padding-right: 20px;
}
.aftercardsCounter1React, {  
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 0.75%;
    padding-bottom: 0.75%;
    background-color: #FFFFFF;
    margin: 0px auto;
    align-content: center;
    flex-direction: row;
    border-radius: 10px 0px 15px 15px;   
}

.aftercardsheiloReact, {  
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 0.75%;
    padding-bottom: 0.75%;
    background-color: #FFFFFF;
    margin: 0px auto;
    align-content: center;
    flex-direction: row;
    border-radius: 15px;   
}
.LigneStromCounter2{
    margin-top: 20px;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: baseline;
        margin-right: 0%;
        width: 97.5%;
    }


.heizolcard{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 2%;
    margin: 0px auto;
    align-content: center;
    flex-direction: row;
    border-radius: 0px 0px 15px 15px; 
    background-color: #F3F3F3;
}
.finalDiv p{
    margin-top: 0;
    
}
.finalDiv{
    display: flex;
    align-items: flex-end;

    margin-left: auto;
}
.subDivs{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    
    margin-right: 0%;
    width:100%;
   
}

.aftercardselectro {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-top: 0.75%;
    padding-bottom: 0.75%;
    background-color: #FFFFFF;
    margin: 0px auto;
    align-content: space-around;
    flex-direction: column;
    border-radius: 15px;
}
.formCameraElectro{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.formElectro{
    display: flex;
    background-color: #FFFFFF;
    width: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin-left: 5%;
}