.hide-after-cards {
  display: none;
}

.hauptmietertest {
  border-radius: 15px 15px 0px 0px;
  width: 393px;
  height: 80px;
  display: flex;
  color: var(--white);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  background-color: #F4BF55;
  margin-right: 0px;
  margin-top: -15px;
  margin-bottom: -15px;
  margin-left: -654px;
  padding-left: 35px;
}

.hauptmietertest h3 {
  top: -10%;
  opacity: 1;
  font-family: 'Myriad Pro Regular', sans-serif;
  text-transform: none;
  color: #ffffff;
  margin-left: 30px;
  margin-bottom: 25px;
  font-size: 22px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}


.wohnungsdaten {
  height: 60px;
  display: flex;
  flex-wrap: wrap;
}
.first_wohnungsdaten.borderRadius{
  border-top-right-radius: 5px !important;
}
.first_wohnungsdaten {
  display: flex;
  color: var(--white);
  background: linear-gradient(146deg, rgba(0, 159, 227, 1) 0%, rgba(2, 110, 156, 1) 100%);
  flex-direction: column;
  padding: 5px 30px ;
  border-top-left-radius: 5px;
}

.first_wohnungsdaten.renter-border {
  border-radius: inherit !important;
}

.padding-center {
  padding: 10px 20px 15px;
  align-items: center;
  height: 100%;
}

.wohnungsdaten h3 {
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-left: 15px;
  margin-bottom: 0;
  /*text-transform: lowercase;*/
}

.wohnungsdaten h3::first-letter {
  text-transform: uppercase;
  /* Capitalize the first letter */
}

.custom-select-owner::first-letter {
  text-transform: none !important;


}

.WohnungsdatenIcon {
  width: 24px;
  height: 30px;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);

}

.subWohnungsdatenIcon {
  width: 40px;
  height: 40px;
  filter: brightness(0) invert(1);

}

.form2mieter {
  display: flex;
  background-color: #FFFFFF;
  width: 89%;
  padding-top: 1%;
  padding-left: 0%;
  padding-bottom: 1%;
  margin: 0px auto;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: stretch;
}


.checkboxHauptmieter {
  --width: 20px;
  --height: calc(var(--width));
  display: inline-block;
  width: var(--width);
  height: var(--height);
  cursor: pointer;
  color: #FFFFFF;

}

.checkbox2mieter {
  --width: 20px;
  --height: calc(var(--width));
  display: inline-block;
  width: var(--width);
  height: var(--height);
  cursor: pointer;
  color: #FFFFFF;

}



.WohnungsdatenImage {
  background-color: #00457C;
  width: 55px;
  padding: 8px;
  border-radius: 10px;
}


#calanderObOw-label {
  position: absolute;
}

#calanderObOw-label.MuiInputLabel-shrink {
  padding-left: 0%;
}


.SonCardHaupmieterBewohner {
  width: 97.5%;
  background-color: #009FE3;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px 15px 15px 15px;
  Opacity: 1;
  margin-top: 25px;
}



.sonsonhaup {
  color: var(--white);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  margin-top: -8px;
  margin-bottom: 8px;
}


.after-cardsMieter {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-top: 0.75%;
  padding-bottom: 0.75%;
  background-color: #FFFFFF;
  margin: 0px auto;
  align-content: center;
  flex-direction: row;
  border-radius: 0px 0px 15px 15px;
}

.after-cardsBEWOHNER {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-top: 0.75%;
  padding-bottom: 0.75%;
  background-color: #FFFFFF;
  margin: 0px auto;
  align-content: center;
  flex-direction: row;
  border-radius: 0px 0px 15px 15px;
}

.first_hauptmieter {
  margin-left: 110px;
  display: flex;
  color: var(--white);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  margin-top: 20px;
  margin-bottom: 4px;
}

.second_hauptmieter {
  margin-left: 110px;
  display: flex;
  color: var(--white);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  margin-top: 20px;
  margin-bottom: 3px;
  align-items: center;
}


.third_hauptmieter {
  margin-left: 110px;
  display: flex;
  color: var(--white);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  margin-top: 20px;
  margin-bottom: 3px;
  align-items: center;
}


.second_hauptmieter h3 {
  opacity: 1;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 30px;
  margin-bottom: 14px;
  width: 169px;
  text-align: left;
}

.third_hauptmieter h3 {
  opacity: 1;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 30px;
  margin-bottom: 14px;
  width: 169px;
  text-align: left;
}


.text-align-center {
  text-align: center;
}

.LbewohnerHR {
  margin-top: 25px;
}

.css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0px;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}


.icon-bewohnerButton {
  margin-left: 10px;
  color: white;
}

.bewohnerButton {
  margin: 15px
}
.ist-input .MuiInputBase-root{
  width: 90% !important;
}
.bewohnerButton button
{
display: flex;
/* position: relative; */
color: #fff;
text-decoration: none;
cursor: pointer;
width: 100%;
height: 50px;
border-radius: 25.5px;
border: 2px solid rgba(255, 255, 255, 1);
opacity: 1;
background-color: rgba(98, 171, 18, 1);
margin-left: 0%;
margin-top: 10px;
opacity: 1;
color: rgba(255, 255, 255, 1);
font-family: 'Myriad Pro Regular', sans-serif;
font-size: 17px;
font-weight: 600;
font-style: normal;
letter-spacing: 0px;
text-align: center;
text-transform: uppercase;
flex-wrap: nowrap;
flex-direction: row;
align-content: center;
align-items: center;
justify-content: center;
}


@media screen and (min-width: 1600px) {
  .hauptmietertest {
    margin-left: -687px;
  }

  .second_hauptmieter {
    margin-left: 47px;
  }

}