.rooms-color {
  background-color: #00457C;
}

.wohnungsdatenRooms .first_wohnungsdatenRooms h3 {
  opacity: 1;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 30px;
  margin-bottom: 9px;
  width: 170px;
  text-align: left;
}


.sub_wohnungsdatenRooms h3 {
  opacity: 1;
  font-family: 'Myriad Pro Regular', sans-serif;
  text-transform: none;
  color: #009FE3;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 10px;
}

.subWohnungsdatenIcon {
  width: 40px;
  height: 40px;
}

.mangelV {
  width: 27.5%;
}

.FotoslV {
  width: 6.5%;
}

.EditierenlV {
  width: 6.6%;
}

.LöschenlV {
  width: 9%;
}

.vl {
  padding: 0;
  border-left: 2px dashed #009fe3;
  height: 80%;
  display: flex;
  align-items: center;
}

.vl h3 {
  color: #009fe3;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  margin-left: 0 !important;
}




.after-cardsBEWOHNER {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-top: 0.75%;
  padding-bottom: 0.75%;
  background-color: #FFFFFF;
  margin: 0px auto;
  align-content: center;
  flex-direction: row;
  border-radius: 0px 0px 15px 15px;
}

.formHauptmieter {
  display: flex;
  background-color: #FFFFFF;
  width: 89%;
  padding-top: 1%;
  padding-left: 0%;
  padding-bottom: 1%;
  margin: 0px auto;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: stretch;
}


.LOvwerview {
  margin-top: -33px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -30px;
}

.LOvwerview a {
  text-decoration: none;
  color: rgba(151, 151, 151, 1);
}

.formHauptmieter p {
  margin-top: 0px;
  margin-bottom: 2px;
}

.padding-top-bottom {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.padding-room-tab {
  padding: 5px 10px;
}

.LOvwerviewHR {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  width: 100%;
  border-bottom: 2px dashed rgba(151, 151, 151, 1);
}
.mr-0{
  margin-right: 0 !important;
}
.ml-0{
  margin-left: 0 !important;
}
.pr-0{
  padding-right: 0 !important;
}
.pl-0{
  padding-left: 0 !important;
}
.full-height {
  height: 100%;
}

.border-dashed-tab {
  border-left: 2px dashed;
  padding: 15px 0px;
  
}
.border-dashed-margin{
  margin-left: -20px;

}


.icon-bewohnerButton {
  margin-left: 10px;
  color: white;
}
.mangelButton a{
  color: rgba(95, 95, 95, 1);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
}
.mangelButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(95, 95, 95, 1);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 18px;
  border: none;
  text-decoration: none;
  padding: 5px 30px;
  border-radius: 26px;
  box-shadow: 0px 1px 2px rgba(57, 57, 57, 0.243019);
  background-image: linear-gradient(180deg, rgba(238, 238, 238, 1) 0%, rgba(216, 216, 216, 1) 100%);

}

.BasketIcon {
  width: 60px;
  height: 69px;
  box-shadow: none;
  border-style: none;
  filter: grayscale(100%);
}
.Camera button{
  position: initial !important;
}
.CameraIcon {
  width: 60px;
  height: 60px;
  box-shadow: none;
  border-style: none;
  filter: contrast(0.5);
}

.PenIcon {
  display: flex;
  margin: 0 auto;
  width: 2rem;
  height: 2rem;
  box-shadow: none;
  border-style: none;
  filter: invert(52%) sepia(54%) saturate(4179%) hue-rotate(168deg) brightness(95%) contrast(101%);
  /*filter: grayscale(1);*/
}
.border-dashed-right{
  border-right: 2px dashed;
}
.icon-AngabeButton {
  color: rgba(151, 151, 151, 1);
  padding: 6px;
}


@media screen and (min-width: 1600px) {
  .sub_wohnungsdatenRooms {
    width: 840px;
    margin-right: -42px;
  }

 
}