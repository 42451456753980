.outer-container {
	margin: 20px 30px;
	padding-top: 130px;

}
/* .MuiDrawer-docked{
	background-color: #00457c;
}
 */
.card-style {
  width: 100%;
  flex-direction: column;
  display: flex;
  margin: auto;
  align-items: center;
  padding: 20px 0px;
}

.datepicker label {
  text-transform: uppercase !important;
}

.color-black {
  color: #333 !important;
}

.icon-black {
  filter: brightness(0%) contrast(10000%) saturate(0%) !important;
}

.SonCard {
  width: 100%;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
}

.cards-row {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  margin: 0px auto !important;
  align-content: center;
  flex-direction: row;
}

.contract-padding {
  padding-left: 30px;
  padding-right: 30px;
}

.cardProtokol {
  height: 260px;
  margin: 20px 5px;
  animation: scl 0.5s ease-in-out;
  transform-origin: left center;
  border-radius: 10px;
  box-shadow: none;
  /* box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.2); */
}

.cardProtokol.card1 {
  background-image: linear-gradient(
    90deg,
    rgba(255, 122, 117, 1) 0%,
    rgb(232, 64, 58) 100%
  );
}

.cardProtokol:hover {
  box-shadow: none;
}

.cardProtokol-title {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Myriad Pro Regular", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
}

.contain-card-type {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.cardProtokol1-desc {
  text-align: center;
}

.cardProtokol2-desc {
  text-align: center;
}

.cardProtokol3-desc {
  text-align: center;
}

.cardProtokol button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  width: 80%;
  padding: 10px 6px;
  border: none;
  margin: 0 auto;
  border-radius: 25.5px;
  border: 1px solid rgba(255, 255, 255, 1);
  opacity: 1;
  background-color: rgba(98, 171, 18, 1);
  color: rgba(255, 255, 255, 1);
  font-family: "Myriad Pro Regular", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
}

.text {
  flex: 1;
  text-align: center;
}

.icon-cardProtokol {
  position: relative;
  width: 16px;
  height: 16px;
  color: white;
}

.cardProtokol .active button {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(98, 171, 18, 1);
  color: rgba(98, 171, 18, 1);
  background-color: rgba(255, 255, 255, 1);
}

.cardProtokol svg {
  margin-left: 10px;
}

.cardProtokol .active SVG {
  color: rgba(98, 171, 18, 1);
}

.EinzugHR {
  margin: 0 auto;
  width: 85%;
  border: none;
  height: 1px;
  background: black;
  opacity: 0.2;
}

.EinzugHR-B {
  margin: 0 auto;
  width: 85%;
  border: none;
  height: 1px;
  background: white;
  opacity: 0.5;
}

.cardProtokol.card2 {
  background-image: linear-gradient(
    270deg,
    rgba(99, 47, 200) 0%,
    rgba(161, 116, 249, 1) 100%
  );
}

.cardProtokol.card3 {
  background-image: linear-gradient(
    90deg,
    rgba(12, 177, 170, 1) 0%,
    rgba(10, 151, 145) 100%,
    rgba(6, 101, 95, 1) 100%
  );
}

.NewlocIcon {
  width: 6rem;
  height: 6rem;
  filter: brightness(0) invert(1);
}

.OldlocIcon {
  width: 6rem;
  height: 6rem;
  filter: brightness(0) invert(1);
}

.NewOldLocIcon {
  width: 6rem;
  height: 6rem;
  filter: brightness(0) invert(1);
}

.cardProtokol :hover {
  box-shadow: 7px 7px 15px 2px transparentize(black, 0.5);
  transition: box-shadow 0.3s ease-in;
}

.protocol.cards-row {
  padding-left: 80px;
  padding-right: 80px;
  border-top-left-radius: 5px;
  margin-top: -3px !important;
  padding-top: 0px !important;
}
.neuer.table-design {
  border-top-right-radius: 0px !important;
}
.table-design-last-part {
  cursor: pointer;
  border-top-right-radius: 8px;
  background-color: #f4bf55;
  display: flex;
  align-items: center;
  justify-content: center;
}
.neuer {
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  background-image: linear-gradient(
    146deg,
    rgba(0, 159, 227, 1) 0%,
    rgba(2, 110, 156, 1) 100%
  );
  padding: 14px 30px;
  height: 60px;
  display: flex;
  color: var(--white);
  align-items: center;
  font-family: "Myriad Pro Regular", sans-serif;

  text-transform: uppercase;
}
.neuer.RoomTitle {
  border-radius: 0px;
  width: 100%;
  height: 100%;
}
.neuer.RoomTitle h3 {
  font-weight: 700;
}
.RoomTitleStatus {
  background-color: rgb(229, 245, 252);
}
.RoomTitleStatus .status-title {
  color: #000;
  font-weight: 400;
  padding-left: 10px;
}
.TextDescription {
  color: rgb(95, 95, 95);
  font-family: Myriad Pro Regular;
  font-weight: 400;
  font-size: 16px;
  text-transform: none;
  position: absolute;
  bottom: 0;
}
.formHauptmieter .MuiFormControl-root .MuiInputBase-input {
  height: 100% !important;
}
.imagesplacement {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.image-grid-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px; /* space between items */
}
.image-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

/* Wrapper for the image and description */
.image-wrapper {
  text-align: center;
}
.room-image {
  width: 100%;
  height: 150px;
  display: block;
  border-radius: 8px;
}
.image-block {
  position: relative;
}
.image-description {
  padding: 10px 0px;
  font-size: 0.9rem;
  color: #555;
}
.image-icon {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: rgb(0, 0, 0, 0.5);
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.loup-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
}
.trash-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
}
.MangelsTab .MuiTabs-scrollButtons {
  display: none;
}
.MangelsTab .MuiAppBar-colorDefault {
  background-color: transparent;
}
.MangelsTab .MuiTabs-indicator {
  height: 4px;
  background-color: #000;
  top: 0;
}
.MangelsTab .MuiTab-textColorPrimary.Mui-selected {
  font-family: 'Myriad Pro Regular';
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.MangelsTab .MuiTab-wrapper {
  font-size: 20px;
  padding: 10px 0px;
}
.MangelsTab .MuiBox-root {
  padding: 0px;
}
.ItemDetail {
  border-left: 8px solid rgb(0, 159, 227);
  border-right: 8px solid rgb(0, 159, 227);
  border-bottom: 8px solid rgb(0, 159, 227);
  margin-right: 10px !important;
  margin-left: 10px !important;
  margin-bottom: 20px;
}
.furniture-item {
  margin-bottom: 20px;
  margin-right: 10px !important;
  margin-left: 10px !important;
  border-radius: 0px;
  background-color: rgb(0, 159, 227);
  background-image: none;
  height: 70px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  color: #fff;
}
.furniture-item .StatusHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  position: relative;
}
.furniture-item h3 {
	font-family:'Myriad Pro Regular';
	font-size: 20px;
	font-weight: 700;
	line-height: 18px;
  margin-left: 10px;
  }
  .furniture-item label {
	font-family:'Myriad Pro Regular';
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 0px;
  }
.furniture-item.Item-opened {
  margin-bottom: 0px;
}
.furniture-item:first-child {
  margin-top: 20px;
}
.furniture-item .StatusHeader .menu-delete-mangel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 6%;
}
.Weiter.ADDNewItem svg {
    fill: rgba(0, 159, 227, 1) !important;
	width: 1em;
    height: 1em;
	right: 2%;
    top: 12%;
}
.btnText.pressed img{
  filter: brightness(0) saturate(100%) invert(27%) sepia(81%) saturate(1316%) hue-rotate(170deg) brightness(95%) contrast(98%);
}
.btnText.pressed span{
  color: rgb(2, 110, 156);
}
.MuiContainer-root.MangelsTab{
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.Block-DeffectDetail .PenIcon{
    width: 3rem;
    height: 3rem;
    object-fit: contain;
    margin-right: 10px;
}
.Deffect-block .NeuerIcon{
margin-top: -10px;
filter: none;
}
.status .custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-align-room .custom-checkbox .MuiRadio-root {
	color: #0093bc;
  }
.flex-align-room .custom-checkbox label {
	font-family:'Myriad Pro Regular';
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 0px;
  }
  .furniture-item label{
    color : rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
  .furniture-item label.checked{
    color: #fff;
  }
  .Room-detail .SimpleMangel-block {
    padding: 6px 45px !important;
    margin-right: 20px;
  }
  .Room-detail  .SimpleMangel-block .icon-registrieren{
  top: 35%;
  width: 1em;
  height: 1em;
  fill: rgb(0, 159, 227);
}
.StatusHeader .custom-checkbox .MuiRadio-root .css-hyxlzm .css-1hbvpl3-MuiSvgIcon-root,
.StatusHeader .custom-checkbox .MuiRadio-root .css-hyxlzm .css-q8lw68{
  color: rgba(255, 255, 255, 0.7);
  font-size: 2.5rem;
}
.status.selected-mangel .custom-checkbox .MuiRadio-root.Mui-checked {
  color: rgb(208, 0, 0);
}
.status.selected-draft .custom-checkbox .MuiRadio-root.Mui-checked {
  color: rgb(215, 124, 0);
}
.status.selected-ok .custom-checkbox .MuiRadio-root.Mui-checked {
  color: rgb(98, 171, 18);
}
.StatusHeader .selected-mangel .custom-checkbox .MuiRadio-root.Mui-checked .css-hyxlzm .css-1hbvpl3-MuiSvgIcon-root,
.StatusHeader .selected-mangel .custom-checkbox .MuiRadio-root.Mui-checked .css-hyxlzm .css-q8lw68{
  color:#fff;
  font-size: 2.5rem;
}
.StatusHeader .selected-mangel .custom-checkbox .MuiRadio-root.Mui-checked .css-hyxlzm .css-11zohuh-MuiSvgIcon-root,
.StatusHeader .selected-mangel .custom-checkbox .MuiRadio-root.Mui-checked .css-hyxlzm .css-1u5ei5s{
  color: rgb(208, 0, 0);
  transform: scale(2.6);
  top: 20%;
  left: 21%;
}
.StatusHeader .selected-draft .custom-checkbox .MuiRadio-root.Mui-checked .css-hyxlzm .css-1hbvpl3-MuiSvgIcon-root,
.StatusHeader .selected-draft .custom-checkbox .MuiRadio-root.Mui-checked .css-hyxlzm .css-q8lw68 {
  color:#fff;
  font-size: 2.5rem;
}
.StatusHeader .selected-draft .custom-checkbox .MuiRadio-root.Mui-checked .css-hyxlzm .css-11zohuh-MuiSvgIcon-root,
.StatusHeader .selected-draft .custom-checkbox .MuiRadio-root.Mui-checked .css-hyxlzm .css-1u5ei5s {
  color: rgb(215, 124, 0);
  transform: scale(2.6);
  top: 20%;
  left: 21%;
}
.StatusHeader .selected-ok .custom-checkbox .MuiRadio-root.Mui-checked .css-hyxlzm .css-1hbvpl3-MuiSvgIcon-root,
.StatusHeader .selected-ok .custom-checkbox .MuiRadio-root.Mui-checked .css-hyxlzm .css-q8lw68{
  color:#fff;
  font-size: 2.5rem;
}
.StatusHeader .selected-ok .custom-checkbox .MuiRadio-root.Mui-checked .css-hyxlzm .css-11zohuh-MuiSvgIcon-root,
.StatusHeader .selected-ok .custom-checkbox .MuiRadio-root.Mui-checked .css-hyxlzm .css-1u5ei5s {
  color: rgb(98, 171, 18);
  transform: scale(2.6);
  top: 20%;
  left: 21%;
}
.MangelsTab .Loschen-button img{
  position: relative;
}
.MangelsTab .Loschen-button .TextDescription{
  line-height: 18px;
}
.Weiter .SimpleMangel-block {
  border-radius: 23px 23px 23px 23px;
  background-color: transparent;
  color: rgb(0, 159, 227);
  text-align: center;
  text-decoration: none;
  border: 2px solid rgb(0, 159, 227);
  cursor: pointer;
  text-transform: uppercase;
  padding: 6px 10px;
  font-weight: 600;
  font-family: "Myriad Pro Regular", sans-serif;
  font-size: 1.2rem;
  width: 100%;
}
.Weiter .Mangel-block {
  border-radius: 23px 23px 23px 23px;
  background-color: transparent;
  color: rgb(208, 0, 0);
  text-align: center;
  text-decoration: none;
  border: 2px solid rgb(208, 0, 0);
  cursor: pointer;
  text-transform: uppercase;
  padding: 6px 10px;
  font-weight: 600;
  font-family: "Myriad Pro Regular", sans-serif;
  font-size: 1.2rem;
  width: 100%;
}
.Weiter .Mangel-block svg {
  width: 1em;
  height: 1em;
  fill: rgb(208, 0, 0) !important;
  right: 2%;
  top: 12%;
}
.neuer h3 {
  font-family: "Myriad Pro Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 15px;
  margin-bottom: 0;
  text-transform: uppercase; /*capitalize !important;*/
}
.Deffect-block .dashed-line {
  border-bottom: 1px dashed rgb(151, 151, 151);
  width: 100%;
  margin: 0px 100px;
  position: relative;
}
.Deffect-block .dashed-line .FirstPart {
  display: flex;
  align-items: center;
  position: absolute;
  background-color: #ffff;
  top: -21px;
  left: -82px;
  width: 250px;
  padding-left: 0px;
}
.Deffect-block .dashed-line .FirstPart span {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}
.Deffect-block .dashed-line .menu-delete-mangel {
  position: absolute;
  right: -100px;
  top: -17px;
  background-color: #fff;
  height: 30px;
}
.Deffect-block .menu-delete-mangel svg {
  color: #000;
}
.neuer h3::first-letter {
  text-transform: uppercase;
  /* Capitalize the first letter */
}
.Block-DeffectDetail {
  margin-left: 30px !important;
  margin-top: 30px !important;
}
.NeuerIcon {
  filter: brightness(0) invert(1);
  width: 24px;
  height: 30px;
}

.after-cards {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
}
.geben.padding {
  padding: 0px 80px !important;
}
.geben {
  padding: 0px 40px;
  background: white;
  color: rgba(0, 159, 227, 1);
  font-family: "Myriad Pro Regular", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  font-style: normal;
  line-height: 24px;
  width: 100%;
}

.contract-padding {
  padding-top: 0px !important;
}

.textfieldsdateplz {
  display: flex;

  background-color: #ffffff;
  margin-top: 1rem;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
  flex-direction: row;
  flex-wrap: nowrap;
}

.plz {
  padding: 0px 60px 0px 10px;
}

.plz .css-13aprtc-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.w-half {
  width: 50%;
}

.weiter-protocolltype button {
  border-radius: 26px 26px 26px 26px;
  background-color: #62ab12;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 15px;
  text-decoration: none;
  border: 1px solid #62ab12;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Myriad Pro Regular", sans-serif;
}

.icon-buttonweiterPT {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  fill: #fff;
}

.calanderIcon {
  position: absolute;
  top: 59%;
  left: 6%;
}

#calander-label {
  padding-left: 10px;
}

#calander-label.MuiInputLabel-shrink {
  padding-left: 0px !important;
}

#calander-label label.Mui-focused {
  padding-left: 0px !important;
}

#calander {
  padding-left: 10px;
  overflow: hidden;
}

.datepicker .MuiFormControl-root {
  width: 100%;
}
.ticket-autocomplete .MuiAutocomplete-hasPopupIcon {
  background-color: #fff !important;
}
