* {
  font-family: var(--heading-font);
}

  .LiegenschaftenIcon {
    width: 23px;
    height:23px;
    margin-right: 1%;
    filter: brightness(0) invert(1);
  }
  .side_real_icon{
    filter: brightness(0);

  }

  .AdresseLiegenschaftIcon {
    width: 1.5rem;
    height: 1.5rem;
    filter: brightness(0) invert(1);
  }

  .SpeichernIcon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 3%;
    filter: brightness(0) invert(1);
  }
  .ERSTELLEN-button svg{
    top: 19px !important;
  }
  .ERSTELLEN-button {
    display: flex;
    text-decoration: none;
    cursor: pointer;
    width: 350px;
    height: 60px;
    border-radius: 35px;
    border: 0.5px solid #62AB12;
    opacity: 1;
    color: #FFFFFF;
    background-color: #62AB12;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 15px 0 rgba(0, 0, 0, 0.09);
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.img-speichern{
  position: absolute;
  right: 20px;
  top: 19px;
}
.Speichern-button {
  display: flex;
  text-decoration: none;
  cursor: pointer;
  width: 350px;
  height: 60px;
  border-radius: 35px;
  border: 0.5px solid #62AB12;
  opacity: 1;
  color: #FFFFFF;
  background-color: #62AB12;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 15px 0 rgba(0, 0, 0, 0.09);
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}
