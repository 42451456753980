
.userFPIcon{
  position: relative;
  opacity: 0.75;
  width: 3.45%;
  margin-bottom: -13.5%;
  margin-left: 3%;
}

.input-fieldFP{
  position: relative;
  opacity: 1;
  margin: auto;
  width: 259%;
  height: 20%;
  border-radius: 4px;
  border-color: #A9A9A9;
  padding-bottom: 9%;  
}

.padding-fpw{
  padding-top: 130px;
  padding-bottom: 130px
}

.text-field-input:focus-within  fieldset{
  border: 2px solid #b3b8cd !important;

}
.text-field-input label.Mui-focused{
  color:#b3b8cd;
}


.FPButton {

  border-radius: 26px;
  background-color: var(--primary);
  padding: 10px 32px;
  text-decoration: none;
  margin: 4px 2px;
  border: 1px solid var(--primary);
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  font-weight: 600;
  font-family:var(--thm-font);
  font-size: 16px; 
  margin-left: 77%;
}

.cardFP {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 7px 4px rgba(0,0,0, 0.2);
  opacity: 1;
  margin-right: auto;
  margin-left: auto;
  background-color: var(--white);
  border-radius: 10px;
  width: 633px;
  height: 301px;
  margin-top: 9%;
  padding-right: 15%;
}

.cardFP-contain {
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.cardFP-contain.padding{
  padding-top: 6%;
  padding-bottom: 6%;
}


#E-Mailadresse-label{
  padding-left: 5%;
}

#E-Mailadresse-label.MuiInputLabel-shrink {
  padding-left: 0%;
}

#E-Mailadresse {
  padding-left: 7.5%;
}

.cancel {
  padding-top: 1%;
  padding-left: 98%;
}

@media (max-width: 450px) {
  .cardFP {
  width: 400px;
  height: 275px;
  margin-top: 20%;
  margin-left: -3%;
  }
  .input-fieldFP {
    position: relative;
    width: 160%;
  }
  #E-Mailadresse {
    padding-left: 9%;
  }
  #E-Mailadresse-label{
    padding-left: 6%;
  }
  .userFPIcon {
    position: relative;
    opacity: 0.75;
    width: 5%;
    margin-bottom: -23%;
    margin-left: 3%;
  }
  .FPButton {
    margin-left: 32%;
  }
  .cancel {
    padding-left: 91%;
  }
}


@media (max-width: 600px) {
  .cardFP {
  margin-top: 20%;
  margin-left: -1%;
  }
}
/*
@media (max-width: 250px)
{.cardFP {
  margin-top: 6%;
  }
  .input-fieldFP {
  position: relative;
  opacity: 1;
  margin: 0%;
  width: 75%;
  height: 20%;
  border-radius: 4px;
  border-color: #A9A9A9;
  padding-bottom: 9%;
}
.userFPIcon {
  position: relative;
  opacity: 0.75;
  width: 8.5%;
  margin-bottom: -44%;
  margin-left: 3%;
}
}

@media (max-width: 600px)
{.cardFP {
  margin-top: 6%;
  }
  .input-fieldFP {
  position: relative;
  opacity: 1;
  margin: auto;
  width: 100%;
  height: 20%;
  border-radius: 4px;
  border-color: #A9A9A9;
  padding-bottom: 9%;
}
.userFPIcon {
  position: relative;
  opacity: 0.75;
  width: 8.5%;
  margin-bottom: -44%;
  margin-left: 3%;
}
}

@media (max-width: 900px)
{.cardFP {
  margin-top: 6%;
  }
  .input-fieldFP {
  position: relative;
  opacity: 1;
  margin: auto;
  width: 175%;
  height: 20%;
  border-radius: 4px;
  border-color: #A9A9A9;
  padding-bottom: 9%;
}
.userFPIcon {
  position: relative;
  opacity: 0.75;
  width: 5%;
  margin-bottom: -20%;
  margin-left: 3%;
}
.FPButton {
  margin-left: 35%;
}
}

 @media (max-width: 1200px)
{
  .cardFP {
  margin-top: 6%;
  }
  .input-fieldFP {
  position: relative;
  opacity: 1;
  margin: auto;
  width: 135%;
  height: 20%;
  border-radius: 4px;
  border-color: #A9A9A9;
  padding-bottom: 9%;
}
.userFPIcon {
  position: relative;
  opacity: 0.75;
  width: 5%;
  margin-bottom: -25%;
  margin-left: 3%;
}
.FPButton {
  margin-left: 18%;
}
#E-Mailadresse-label {
  padding-left: 5%;
}
}

@media (min-width: 1200px)
{.input-fieldFP {
  position: relative;
  opacity: 1;
  margin: auto;
  width: 260%;
  height: 20%;
  border-radius: 4px;
  border-color: #A9A9A9;
  padding-bottom: 9%;
}

.userFPIcon {
    position: relative;
    opacity: 0.75;
    width: 3.5%;
    margin-bottom: -13%;
    margin-left: 3%;
}
.FPButton {
  margin-left: 77%;
}
#E-Mailadresse-label {
  padding-left: 5%;
}
} */

.error {
  color: rgb(184, 6, 6);
  margin-left:  9%;
}

.success {
  color: rgb(2, 73, 2);
  margin-left:  9%;
  
}
