.swal-text {
  font-family: 'Myriad Pro Regular', sans-serif !important;

}

.swal-modal {
  font-family: 'Myriad Pro Regular', sans-serif !important;

}

.swal-button {
  font-family: 'Myriad Pro Regular', sans-serif !important;

}

.sub-schussel {
  border-radius: 15px 15px 0px 0px;
  width: 828px;
  height: 80px;
  display: inline-flex;
  color: #009FE3;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  background-color: #00457C;
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: -8px;
  align-content: center;
  flex-direction: row;
  padding-bottom: 17px;
  align-items: flex-end;
}

.padding-col-key {
  padding-left: 15px;
  padding-right: 15px;
}

.ml-5 {
  margin-left: 5px !important;
}


.sub-schussel h3 {
  opacity: 1;
  font-family: 'Myriad Pro Regular', sans-serif;
  text-transform: none;
  color: #009FE3;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 10px;
}

#soll,
#ist {
  text-align: center;
}

#schlusselname-schlussel {
  margin-left: -8%;
}

/* #calanderObOw {
    padding-left: 10%;
  } */

.bemerkungenprotokollkey {
  width: 171px;
}

.divcalender {
  width: 176px;
}

@media screen and (min-width: 1600px) {
  .sub-schussel {
    width: 880px;
    margin-right: 0px;
  }

  .bemerkungenprotokollkey {
    width: 180px;
  }

  .divcalender {
    width: 20.35%;
  }
}