
* {
  font-family: var(--heading-font);
}

.LiegenschaftListIcons {
    width: 1.5rem;
    height: 1.5rem;
    /* filter: invert(50%) sepia(63%) saturate(5633%) hue-rotate(170deg) brightness(99%) contrast(101%); */
  }

  .Weiter .hinzufugenButton {
    border-radius: 26px 0px 0px 26px;
    background-color: #62AB12;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border: 1px solid #62AB12;
    cursor: pointer;
    width: 210px;
    height: 50px;
    font-weight: 600;
    font-family: 'Myriad Pro Regular', sans-serif;
    font-size: 20px;
  }

  .Weiter .hinzufugenButton svg {
    width: 30px;
    height: 30px;
  }
    .exportXml {
      cursor: pointer;
      padding: 5px 5px;
      display: block;
      border-radius: 0px 0px 0px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: "flex";
       align-items: "center"
    }

        .exportXml .SaveIcon {
          height: 28px;
        }