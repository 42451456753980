.select-button {
    width: 100%;
}

.select-button button {
    width: 100%;
    justify-content: center;
    margin: 0 auto !important;
}

.select-button p {
    margin-bottom: 0;
}

.select {
    flex: 4;
}

.graLine {
    margin-top: 0rem;
}

.cards-row1 {
    width: 100%;
    border-radius: 10px 0% 0% 0%;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-top: 0.75%;
    padding-bottom: 0.75%;
    background-color: #FFFFFF;
    margin: 0px auto;
    align-content: center;
    flex-direction: row;
    height: 100%;
}

.whiteBox {
    width: 25%;
}

.cardProtokolStrom1 {

    display: contents;
    width: 13%;
    min-width: 50px;
    cursor: pointer;
    height: 150px;
    /* border: 1px solid transparentize(grey,0.7); */

    animation: scl 0.5s ease-in-out;
    transform-origin: left center;
}


.form {
    width: 100%;
    margin-left: 3%;
}



.avataruser {

    width: 90px;
    height: 100px;
    margin-right: 5px;
    object-fit: contain;
    filter: opacity(0.5) drop-shadow(0 0 0 #009FE3);
}

.avataruser1 {

    width: 90px;
    height: 100px;
    margin-right: 5px;
    object-fit: contain;
    filter: opacity(0.2) drop-shadow(0 0 0 #d0d2d3);
}



.neuer1 {
    padding-left: 1%;
    padding-bottom: 1%;
    display: flex;
    color: var(--white);
    font-family: var(--thm-font);
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: -50px;
    margin-top: -10px;
}

.neuer1 h3 {
    position: inherit;
    opacity: 1;
    font-family: var(--thm-font);

    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
}

.topTitle {
    padding-top: 15px;
}

.topTitle h3 {
    color: rgba(0, 159, 227, 1);
    position: inherit;
    font-family: var(--thm-font);
    font-size: 1.2rem;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;

}

.r-0 {
    right: 0 !important;
}

.padding-product {
    padding-left: 10px;
    padding-right: 10px;
}

.textfieldsdateplz1 {
    margin-top: 10rem;
    width: 89%;
    display: flex;

    padding-bottom: 0.75%;
    background-color: #FFFFFF;
    margin-top: 6rem;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0%;
    flex-direction: row;
    flex-wrap: nowrap;
}

.geben1 {
    position: absolute;
    margin: 16px 0px 35px 0px;

    padding-top: 20px;
    width: 55%;
    background: white;
    opacity: 1;
    color: rgba(0, 159, 227, 1);
    font-family: var(--thm-font);
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;

}



.blankPage {
    display: flex;
    width: 100%;
}


.after-cards1 {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: flex-start;
    position: relative;
    /* padding-top: 7%;
    padding-bottom: 10%; */
    background-color: #FFFFFF;
    margin: 0px auto;
    padding: 8px 0px 0px 22px;
    align-content: center;
    flex-direction: column;
    border-radius: 0px 0px 15px 15px;
}

.submitDiv {
    width: 100%;
    height: 100%;
    padding: 3%;

}

.inferior {
    margin-left: 3%;
}

.weiter-protocolltype button:disabled {
    background-color: #9c9e9a !important;
    color: #fff;
    text-decoration: none;
    margin: 4px 2px;
    border: 1px solid #9c9e9a;
    cursor: not-allowed;
}

.card-container .padding-col {
    padding-right: 5px;
    padding-left: 5px;
}

.submitButton  svg {
    top: 15px !important
}

.submitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 26px 26px 26px 26px;
    background-color: #62AB12;
    color: #fff;
    padding: 10px;
    text-decoration: none;
    border: 1px solid #62AB12;
    cursor: pointer;
    text-transform: uppercase;
    width: 80%;
    height: 53px;
    font-weight: 600;
    font-family: var(--thm-font);
    font-size: 1rem;
    margin: 0 auto;
}


.resultFilter {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-bottom: 10px;
}

.weiter-contract {
    padding: 11px;
}

.plz1 {
    padding-left: 2%;
}


.plz1 .label {
    font-weight: 900;

}

.calanderIcon {
    position: absolute;
    top: 59%;
    left: 6%;
}


#calander-label {
    padding-left: 11%;

}

#calander-label.MuiInputLabel-shrink {
    padding-left: 0%;
}

#calander {
    padding-left: 19%;
    overflow: hidden;

}