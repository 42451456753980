
.userIcon{
  position: relative;
  opacity: 0.5;
  width: 20px;
  margin-bottom: -16.5%;
  margin-left: 3%;
}

.passwordIcon{
  position: relative;
  opacity: 0.75;
  width: 20px;
  margin-bottom: -16.5%;
  margin-left: 3%;
}

.infoIcon{
  width: 20px;
  height: 20px;
}

.title-login{
  color: var(--white);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 26px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
}
.centred-title{
  margin: 0 auto;
}

h2.TitleLogin {
  font-size: 58px;
  font-weight: bold;
  color: black;
  text-align: left;
}

.input-field{
  position: relative;
  width: 100%;
  border-radius: 4px;
  border-color: #A9A9A9;
  
}

.text-field-input:focus-within  fieldset{
  border: 2px solid #b3b8cd !important;

}
.text-field-input label.Mui-focused{
  color:#b3b8cd;
}
.LoginButton:disabled{
  background-color: gray;
    border-color: gray;
    cursor: not-allowed;
  
}
.LoginButton {
  display: flex;
    align-items: center;
    justify-content: center;
  border-radius: 26px;
  background-color: var(--primary);
  padding: 10px 32px;
  text-decoration: none;
  margin: 4px 2px;
  border: 1px solid var(--primary);
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  font-weight: 600;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 16px; 

}
.RedirectLoginButton {
  align-self: flex-end; /* Align the button to the right */
  border-radius: 26px;
  background-color: var(--primary);
  padding: 10px 32px;
  text-decoration: none;
  border: 1px solid var(--primary);
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 300;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 16px;
  margin-top: 0px;
  margin-right: 5px;
}
.card-register{
  width: 60% !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 7px 4px rgba(0,0,0, 0.2);
  opacity: 1;
  margin-right: auto;
  margin-left: auto;
  background-color: var(--white);
  border-radius: 10px;
  width: 533px;

}
.card-register.card-contain{
  width: 100% !important;
}
.card-contain {
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
  
}
.card-contain.padding{
  padding-top: 0.75%;
  padding-bottom: 0.75%;
}


.forgetPass{
  margin-bottom: 0px !important;
  text-align: center;
  color: #000000;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-decoration : none;
}

.signuplink{
  opacity: 1;
  color: #000000;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-decoration : none;
}

.LoginAbout {
  text-decoration: none;
  justify-content: center;
  color: #000;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.LoginAbout:hover a {
  color: #00457c;
}


.LoginAbout a {
  padding-left: 1.25%;
  color: #000000;
}

.input-field  label{
  padding-left: 30px !important;
}
.input-field  label.MuiInputLabel-shrink{
  padding-left: 0px !important;
}
.input-field  label.Mui-focused{
  padding-left: 0px !important;
}
.input-field  label:focus-visible{
  padding-left: 0px !important;
}
.input-field label.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
  padding-left: 0px !important;

}
.margin-login{
  padding-top: 65px;
  padding-bottom: 65px;
  height: 100%;
}
@media (max-width: 450px) {
  .card {
  width: auto;
  height: auto;
  margin-top: auto;
  margin-left: auto;
  }
  .userIcon{
    margin-bottom: -26.5%;
    margin-left: 3%;
    width: 4.5%;
  }
  
  .passwordIcon{
    margin-bottom: -26.5%;
    margin-left: 3%;
    width: 4%;
  }

}

.error {
  color: rgb(184, 6, 6);
}
.agb-container{
  display: flex;
  align-items: center;
}
.margleft {
  display: initial;
    margin-bottom: 0px;
    margin-left: 10px;
}