.wohnungsdaten {
  display: flex;
  color: var(--white);
  text-transform: uppercase;
}

.protocol-step_border {
  margin-top: -3px;
  position: relative;
  border-top-left-radius: 5px;
  z-index: 5;
}
.landlord-block{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sub_wohnungsdaten {
  border-top-right-radius: 5px;
  flex: 1;
  max-width: 100%;
  color: var(--white);
  background-color: #F4BF55;
}

.sub_wohnungsdaten.renter-margin {
  margin-left: 0 !important;
  z-index: 4;
}

.sub_wohnungsdaten .renter-padding {
  padding: 14px 30px;
}

.sub_wohnungsdaten h3 {

  font-family: 'Myriad Pro Regular', sans-serif;
  text-transform: none;
  color: #000;
  font-size: 18px;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}
.list-item{
  width: 100%;
  justify-content: left !important;
  font-size: 1.1rem !important;
}

.sub_wohnungsdaten .center {
  align-items: center;
  padding: 20px 0px;
}

.subWohnungsdatenIcon {
  width: 40px;
  height: 40px;

}

.step-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.textfieldsdateplzObOw {
  display: flex;
  background-color: #FFFFFF;
  padding: 5px 20px;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: stretch;
}


.checkbox {
  display: inline-block;
  cursor: pointer;
  color: #FFFFFF;

}
.counter_info .toggle {
  --width: 62px;
  --height: calc(var(--width) / 2.5);
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  cursor: pointer;
  text-align: center;
}
.toggle {
  --width: 80px;
  --height: calc(var(--width) / 2.5);
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  cursor: pointer;
  text-align: center;
}

.toggle {
  margin-bottom: 0px !important;
}

.toggle input {
  display: none;
  position: relative;
}

.toggle .slider {
  --width: 90px;
  --height: 30px;
  display: flex;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  background-color: #565050db;
  transition: all 0.4s ease-in-out;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.toggle .slider::before {
  content: '';
  margin-left: 4px;
  width: 20px;
  height: 20px;
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked+.slider {
  background-color: #00457C;
}

.toggle input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}


.toggle .sliderCounter {
  --width: 69px;
  --height: 33px;
  display: flex;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  background-color: #565050db;
  transition: all 0.4s ease-in-out;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.toggle .sliderCounter::before {
  content: '';
  margin-left: 4px;
  width: 20px;
  height: 20px;
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked+.sliderCounter {
  background-color: #00457C;
}

.toggle input:checked+.sliderCounter::before {
  transform: translateX(calc(var(--width) - var(--height)));
}
.MuiFormControl-root button svg{
  position: relative !important;
  top: inherit !important;
  right: inherit !important;
}
.toggle .labels-appartment{
  top: -30px !important;
}
.toggle .labels {
  position: relative;
  left: 2px;
  top: -25px;
  width: 100%;
  height: 100%;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
  content: attr(data-off);
  right: 3px;
  color: #ffffff;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease-in-out;
}

.toggle .labels::before {
  content: attr(data-on);
  left: 30px;
  color: #ffffff;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked~.labels::after {
  opacity: 0;
}

.toggle input:checked~.labels::before {
  opacity: 1;
}




#calanderObOw-label {
  position: absolute;
}

#calanderObOw-label.MuiInputLabel-shrink {
  padding-left: 0%;
}

/* #calanderObOw {
	padding-left: 3%;
	// overflow: hidden;
} */

.wohnungsdatenL1 {
  margin-top: 0px;
}

.wohnungsdatenL2 {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.wohnungsdatenL2 .WohnungsdatenImage {
  margin-right: 15px;
}

.wohnungsdatenL2 .strasse {
  margin-right: 15px;
}

.wohnungsdatenL2 .hnr {
  margin-right: 15px;
}

.wohnungsdatenL3 {
  margin-top: 15px;
}

.wohnungsdatenL4 {
  margin-top: 15px;
}

.wohnungsdatenL4 .plz {
  margin-right: 15px;
}

.wohnungsdatenL5 {
  margin-top: 15px;
}




.hide-after-cards {
  display: none;
}

.first_eigentumer {
  margin-left: 330px;
  display: flex;
  color: var(--white);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-start;
}


.css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0px;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}