#imgStep {
  filter: invert(1);
}

.ProtokolIcon {
  width: 33px;
  height: 33px;
}

.WohnunIcon {
  width: 30px;
  height: 30px;
}

.MiterIcon {
  width: 30px;
  height: 30px;
}

.ZimmerIcon {
  width: 30px;
  height: 30px;
}

.VerbrauchIcon {
  width: 30px;
  height: 30px;
}

.AbschlussIcon {
  width: 30px;
  height: 30px;
}

.KeyIcon {
  width: 30px;
  height: 30px;
}
