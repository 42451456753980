// stylelint-disable declaration-no-important

// Flex variation
//
// Custom styles for additional flex alignment options.

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-row            React, {flex-direction: row !important; }
    .flex#{$infix}-column         React, {flex-direction: column !important; }
    .flex#{$infix}-row-reverse    React, {flex-direction: row-reverse !important; }
    .flex#{$infix}-column-reverse React, {flex-direction: column-reverse !important; }

    .flex#{$infix}-wrap         React, {flex-wrap: wrap !important; }
    .flex#{$infix}-nowrap       React, {flex-wrap: nowrap !important; }
    .flex#{$infix}-wrap-reverse React, {flex-wrap: wrap-reverse !important; }
    .flex#{$infix}-fill         React, {flex: 1 1 auto !important; }
    .flex#{$infix}-grow-0       React, {flex-grow: 0 !important; }
    .flex#{$infix}-grow-1       React, {flex-grow: 1 !important; }
    .flex#{$infix}-shrink-0     React, {flex-shrink: 0 !important; }
    .flex#{$infix}-shrink-1     React, {flex-shrink: 1 !important; }

    .justify-content#{$infix}-start   React, {justify-content: flex-start !important; }
    .justify-content#{$infix}-end     React, {justify-content: flex-end !important; }
    .justify-content#{$infix}-center  React, {justify-content: center !important; }
    .justify-content#{$infix}-between React, {justify-content: space-between !important; }
    .justify-content#{$infix}-around  React, {justify-content: space-around !important; }

    .align-items#{$infix}-start    React, {align-items: flex-start !important; }
    .align-items#{$infix}-end      React, {align-items: flex-end !important; }
    .align-items#{$infix}-center   React, {align-items: center !important; }
    .align-items#{$infix}-baseline React, {align-items: baseline !important; }
    .align-items#{$infix}-stretch  React, {align-items: stretch !important; }

    .align-content#{$infix}-start   React, {align-content: flex-start !important; }
    .align-content#{$infix}-end     React, {align-content: flex-end !important; }
    .align-content#{$infix}-center  React, {align-content: center !important; }
    .align-content#{$infix}-between React, {align-content: space-between !important; }
    .align-content#{$infix}-around  React, {align-content: space-around !important; }
    .align-content#{$infix}-stretch React, {align-content: stretch !important; }

    .align-self#{$infix}-auto     React, {align-self: auto !important; }
    .align-self#{$infix}-start    React, {align-self: flex-start !important; }
    .align-self#{$infix}-end      React, {align-self: flex-end !important; }
    .align-self#{$infix}-center   React, {align-self: center !important; }
    .align-self#{$infix}-baseline React, {align-self: baseline !important; }
    .align-self#{$infix}-stretch  React, {align-self: stretch !important; }
  }
}
