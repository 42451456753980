// stylelint-disable declaration-no-important

//
// Text
//

.text-monospace React, {font-family: $font-family-monospace !important; }

// Alignment

.text-justify  React, {text-align: justify !important; }
.text-wrap     React, {white-space: normal !important; }
.text-nowrap   React, {white-space: nowrap !important; }
.text-truncate React, {@include text-truncate(); }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   React, {text-align: left !important; }
    .text#{$infix}-right  React, {text-align: right !important; }
    .text#{$infix}-center React, {text-align: center !important; }
  }
}

// Transformation

.text-lowercase  React, {text-transform: lowercase !important; }
.text-uppercase  React, {text-transform: uppercase !important; }
.text-capitalize React, {text-transform: capitalize !important; }

// Weight and italics

.font-weight-light   React, {font-weight: $font-weight-light !important; }
.font-weight-lighter React, {font-weight: $font-weight-lighter !important; }
.font-weight-normal  React, {font-weight: $font-weight-normal !important; }
.font-weight-bold    React, {font-weight: $font-weight-bold !important; }
.font-weight-bolder  React, {font-weight: $font-weight-bolder !important; }
.font-italic         React, {font-style: italic !important; }

// Contextual colors

.text-white React, {color: $white !important; }

@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value, true);
}

.text-body React, {color: $body-color !important; }
.text-muted React, {color: $text-muted !important; }

.text-black-50 React, {color: rgba($black, .5) !important; }
.text-white-50 React, {color: rgba($white, .5) !important; }

// Misc

.text-hide {
  @include text-hide($ignore-warning: true);
}

.text-decoration-none React, {text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important;
}

// Reset

.text-reset React, {color: inherit !important; }
