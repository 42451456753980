.card-container {
  background-color: white;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px -4px 10px 5px #ccc;
  border-color: black;
  cursor: pointer;
  margin: 4px;
}
.card-container:hover {
  transform: scale(1.02);
}
.card-container .subItem:nth-child(2) {
  margin-left: 20px;
}

.MaklerCard{
  margin: 0px !important;
}
.MaklerCard .toggle .slider {
  background-color: rgba(86, 80, 80, .4);
}
.MaklerCard .toggle input:checked+.slider {
  background-color: #009FE3;
}
.MaklerCard.clicked{
  border: 3px solid rgba(0, 159, 227, 1) !important; 
  box-shadow: 0px -4px 10px 5px #ccc !important;
  border-radius: 10px 10px 8px 8px !important;
}
.MaklerCard:hover {
  transform: scale(1.01);
}
.card-container.clicked {

  border: 2.5px solid rgb(140, 140, 252);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.5);
  margin : 0px;
  cursor: pointer;
}
.card-container.clicked .inferiorItem{
  margin: 0px 2px 2px;
  border-radius: 0px 0px 4px 4px;
}
.card-containerCounterProductCard {
  background-color: white;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px -4px 10px 5px #ccc;
  /* border-color: black; */
  border: 0.1px solid #888888;
  cursor: pointer;
}

.card-containerCounterProductCard .subItem:nth-child(2) {
  margin-left: 80px;
}

.card-containerCounterProductCard.clicked {

  border: 2px solid rgba(0, 159, 227, 1); 
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px -4px 10px 5px #ccc;
  cursor: pointer;
}

.gas {
  display: flex;
  margin: 8px 8px 0 0;
}

.topItem:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 8px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

.topItem .gas p {
  vertical-align: middle;
  display: contents;
  line-height: revert;
  flex: 2;
  opacity: 1;
  color: #0cb1aa;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 22px;
}

.arrowCard {
  margin: 7px;
}

.ZumIconCard {
  filter: saturate(500%) contrast(800%) brightness(500%) invert(50%) sepia(304%) hue-rotate(466deg);

  width: 30px;
  height: 30px;
}

.topItem {
  position: relative;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 10px 10px 0px 0px;
  background-color: rgba(255, 255, 255, 1);
  padding-top: 20px;
  padding-bottom: 20px;
}
.MaklerCard.card-green-bg{
  background: linear-gradient(90deg, rgba(67 155 134) 0%, rgba(32 66 65) 100%, rgba(35 116 109) 100%);
  border-radius: 20px 20px 8px 8px;
}
.card-green {
  padding: 15px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 79.3%;
}
.color-red-icon {width: 30px;
  height: 30px;
}

.color-red-user{
  color: red;
}
.MaklerCard.card-red-bg{
  background: linear-gradient(90deg, rgb(243 110 17) 100%, #f8a227 0%, rgb(207 83 11) 100%);
  border-radius: 20px 20px 8px 8px;
}
.card-red {
  padding: 15px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 79.3%;
}

.topItem p {
  margin-bottom: 0;
  text-transform: none;
  font-size: 16px;
}

.subMaklerName h3 {
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  word-wrap: break-word;
}

.subMaklerName h4 {
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  color: rgb(215, 240, 231);
}


.subMaklerName span {
  color: #fff;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  word-wrap: break-word;
}

.topItem h3 {
  vertical-align: middle;
  color: rgba(0, 0, 0, 1);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
}

.triangle-top {
  width: 40px;
  height: 0px;
  border: 20px solid;
  border-top-color: white;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  margin-left: 96px;

  margin-top: -25px;
}

.inferiorItem.padding-card {
  padding: 20px 5px;
}
.card-containerCounterProductCard.clicked .inferiorItem {
  margin: 0px 2px 2px;

}
.card-containerCounterProductCard:hover {
  transform: scale(1.02);
}
.card-containerCounterProductCard .inferiorItem {
  border-radius: 0px 0px 4px 4px;
}
.inferiorItem {
  background: linear-gradient(90deg,
      rgba(67 155 134) 0%,
      rgba(32 66 65) 100%,
      rgba(35 116 109) 100%);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  padding: 5px 15px;
}

.subItem {
  width: 50%;
}

.item h3 {
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.item span {
  color: #fff;
}

#line {
  margin-left: 4%;
  opacity: 0.4;
  width: 90%;
  background-color: rgb(206, 197, 197);
  height: 0.5px;
}

.toggle.card--toggle {
  display: flex;
  justify-content: end;
}

.padding-calculator {
  padding: 0 60% 0 0;
}

.toggle .slider.admin-card {
  --width: 60px !important;
}

.toggle .slider.admin-card-counter {
  --width: 56px !important;
}

.toggle .slider.admin-card-secondcounter {
  --width: 53px !important;
}

/*
* 10.tab styles
*/

.tab-title {
  border-bottom: inherit !important;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 0;
  justify-content: center;
  font-size: 0;
  margin-bottom: 1rem;
}

.tab-title .nav-item {
  display: inline-block;
  margin-bottom: 0 !important;
}

.tab-title .nav-item a {
  display: block;
  border: none;
  outline: none;
  color: #86909e;
  display: inline-block;
  padding: 14px 75px;
  cursor: pointer;
  color: #777b92;
  font-size: 18px;
  font-weight: 500;
  border-radius: 0 !important;
  transition: all 0.4s ease;
  border-style: solid;
  border-width: 1px;
  border-color: #eaeaea;
  background-color: #fefdfe;
  box-shadow: 0px 15px 30px 0px rgba(119, 123, 146, 0.1);
}

.tab-title .nav-item a:hover {
  border-color: linear-gradient(90deg, #439b86, #204241 100%, #23746d 0);
  background: linear-gradient(90deg, #439b86, #204241 100%, #23746d 0) !important;

  color: #ffffff;
}

.topItem.padding {
  padding-right: 5px;
  padding-left: 5px;
}

.tab-title .nav-item a.active {
  border-color: linear-gradient(90deg, #439b86, #204241 100%, #23746d 0);
  background: linear-gradient(90deg, #439b86, #204241 100%, #23746d 0) !important;
  color: #ffffff !important;
}

.tab-title .nav-item:first-child a {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.tab-title .nav-item:last-child a {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

.productName {
  min-height: 60px;
}

.productName h3 {
  vertical-align: middle;
  display: contents;
  line-height: normal;
  color: white;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 22px;
}

.text-align-end {
  text-align: end;
}

.w-full {
  width: 100% !important;
}

.padding-bank-left {
  padding-left: 5px;
}

.padding-bank-right {
  padding-right: 5px;
}

.end-position {
  display: flex;
  justify-content: end;
}

/* ------------------ */
.newcardwidth {
  min-width: 33.33%;
}

/* ------------------ */