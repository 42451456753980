.titleZahlerList {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
}

.titleZahlerList button {
    background: none;
    border: none;
}
.BestatigenButton .cancel{
    background-color: red;
    border: red;
    font-size: 18px !important;
    text-transform: lowercase !important;
    width: 220px !important;
}
.BestatigenButton button {
    display: flex;
    text-decoration: none;
    border-radius: 30px;
    border: 1px solid rgba(98, 171, 18, 1);
    opacity: 1;
    background-color: rgba(98, 171, 18, 1);
    color: rgba(255, 255, 255, 1);
    font-family: 'Myriad Pro Regular', sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 270px;
    height: 55px;
    padding-left: 9%;
}

.firstanmeldenButton button {
    display: flex;
    text-decoration: none;
    border-radius: 30px;
    border: 1px solid rgba(98, 171, 18, 1);
    opacity: 1;
    background-color: rgba(98, 171, 18, 1);
    color: rgba(255, 255, 255, 1);
    font-family: 'Myriad Pro Regular', sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 270px;
    height: 55px;
}

.secondanmeldenButton button {
    display: flex;
    text-decoration: none;
    border-radius: 30px;
    border: 1px solid rgba(98, 171, 18, 1);
    opacity: 1;
    background-color: #FFFFFF(98, 171, 18, 1);
    color: rgba(98, 171, 18, 1);
    font-family: 'Myriad Pro Regular', sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 270px;
    height: 55px;
}