.layoutstepsbar {
  background-color: #00457C;
  position: fixed;
  display: block;
  top: 0;
  left: 300px;
  width: calc(100% - 300px);
  /* Sticky to the top */
  z-index: 1000;
  box-sizing: border-box;

}

.layoutcontent {

  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
  margin: auto;
}

.einzug {
  flex-grow: 1;
  padding: 5px 40px;
  align-items: center;
  width: 100%;
  display: flex;
  color: var(--white);
  font-family: 'Myriad Pro Regular', sans-serif;
  font-style: normal;
  letter-spacing: 0px;
  text-transform: uppercase;

}


.einzug h3 {
  margin: 0;
  font-family: 'Myriad Pro Regular', sans-serif;
  line-height: 18px;
  font-size: 1rem;
  font-weight: 700;
}

.DashSaveMenu {
  background-color: #009FE3;
  width: 70px;
  height: 50px;
  cursor: pointer;
  padding: 5px 0px;
  display: block;
  border-radius: 0px 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ZumIcon {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.DashSaveMenu .SaveIcon {
  max-height: 2.3rem;
}



.step-container {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.step-container-import {
  align-items: center;
}

.font-status_draft {

  display: flex;
  align-items: center
}

.font-status_draft span {
  font-size: 16px !important;
  color: rgb(84 184 0) !important;
  margin-top: 7px;

}

.font-status_closed {
  display: flex;
  align-items: center
}

.font-status_closed span {
  font-size: 16px !important;
  color: #b1b1b1 !important;
  margin-top: 7px;
}

.steps {
  width: calc(70% - 40px);
  padding: 25px 20px;
  margin: 0 auto
}



.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.Weiter .buttonaddMakler {
  border-radius: 5px 5px;
  background-color: #62AB12;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border: 1px solid #62AB12;
  cursor: pointer;
  text-transform: uppercase;
  padding: 2px 10px;
  font-weight: 600;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 1rem;
}

.Weiter svg {
  width: 16px;
  height: 16px;
  fill: #fff;
  vertical-align: middle;
}

.steps img {
  width: 1.3rem;
  height: 1.3rem;
  filter: brightness(0.5);
}

.steps div .MuiStepConnector-line {
  background-color: #1B8EE9;
  margin: 0px 2px;
}

.MuiStepLabel-labelContainer {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, -130%);

}

.bg-row {
  padding: 10px 0px;
  box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin: 5px 0px;
}

.step div {
  background-color: #1B8EE9;
  z-index: 1;
  color: #fff;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: linear-gradient(136deg, #1B8EE9 0%, #1B8EE9 50%, #1087e5 100%);
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 25%);
  cursor: pointer;
}

.step {
  border: 3px solid #1B8EE9;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 3px;
}

.steps .MuiStepConnector-root.Mui-active .MuiStepConnector-line {
  background-image: linear-gradient(95deg, #1B8EE9 0%, #1B8EE9 50%, #1087e5 100%);
}


.steps div .css-gujj89 {
  background-color: #ccc;
  z-index: 1;
  color: #fff;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: linear-gradient(136deg, #F4BF55 0%, #F4BF55 50%, #F4BF55 100%);
}

.steps span .MuiStepLabel-label {
  background-color: #F4BF55;
  border-radius: 0px;
  margin: 0;
  color: #00457c;
  padding: 5px 0px;
  font-size: .7rem;
}

.steps span .MuiStepLabel-label:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 8px solid #F4BF55;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

.steps span .MuiStepLabel-label.Mui-completed {
  color: #00457C;
  font-weight: 400;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: .7rem;
  padding: 3px;
}

.ProtokolIcon {
  width: 33px;
  height: 33px;
}

.WohnunIcon {
  width: 30px;
  height: 30px;
}

.MiterIcon {
  width: 30px;
  height: 30px;
}

.ZimmerIcon {
  width: 30px;
  height: 30px;
}

.VerbrauchIcon {
  width: 30px;
  height: 30px;
}

.AbschlussIcon {
  width: 30px;
  height: 30px;
}

.KeyIcon {
  width: 30px;
  height: 30px;
}

.custom-carousel .carousel-control-next,
.custom-carousel .carousel-control-prev {
  background-color: red !important;
  border: none;
}

.custom-carousel .carousel-control-next-icon,
.custom-carousel .carousel-control-prev-icon {
  color: white;
  /* Icon color */
}

.carousel-indicators {
  align-items: center;
  position: relative;
}

.carousel-indicators {
  margin-top: 40px;
}

.carousel-indicators button {

  margin-right: 10px;
  margin-left: 10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 0.4;
  background-color: #9C9C9C;
  color: #333;
  cursor: pointer;
  overflow: hidden;
  opacity: 1;
  border: 4px solid transparent;
}

.carousel-indicators .active {
  opacity: 1;
  background-color: #009fe3;
  z-index: 3;
}


.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #009fe3;
  background-size: 100% 40%;
  border-radius: 50%;

}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}