// stylelint-disable declaration-no-important

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} React, {#{$prop}: $length !important; }
  }
}

.mw-100 React, {max-width: 100% !important; }
.mh-100 React, {max-height: 100% !important; }

// Viewport additional helpers

.min-vw-100 React, {min-width: 100vw !important; }
.min-vh-100 React, {min-height: 100vh !important; }

.vw-100 React, {width: 100vw !important; }
.vh-100 React, {height: 100vh !important; }
